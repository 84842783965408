import BaseAPIs from "./base.apis";
import {iMaster} from "../reducer";
import axios from "axios";

export default class CommonApis extends BaseAPIs {
    getAllMasters = async (): Promise<iMaster> => {
        return axios
            .get(this.getApiBaseURL() + "/_api/v1/master", {
                headers: {"Content-Type": "application/x-www-form-urlencoded"},
                withCredentials: true,
            })
            .then((res): iMaster => ({statusCode: res.status, ...res.data}))
            .catch((error): any => {
                return this.handleCatch(error);
            })
    };
}
