import React, {useContext} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    FormControl, Select, FormHelperText, Checkbox, FormControlLabel, LinearProgress,
} from "@material-ui/core";
import {Formik} from "formik";
import * as yup from "yup";
import useStyles from "./style"
import {Button} from "../../components/Wrappers";
import UserAPIs from "../../apis/user.apis";
import {HandleErrors} from "../../helper/form.helper";
import useIsMounted from "ismounted";
import {AppStateContext} from "../../App";
import TrainingVideosAPIs, {iTrainingVideos} from "../../apis/trainingVideos.apis";
import {Text} from "recharts";

const NewTrainingVideo = ({default_values, isModalVisible, setModalVisible, trainingVideoCreated}: {
    default_values?: iTrainingVideos, isModalVisible: boolean,
    setModalVisible: any, trainingVideoCreated: any
}) => {

    const classes = useStyles();


    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            height: "60vh",
            width: "30vw",
            backgroundColor: "#F6F7FF",
            overflow: "auto"
        },
    };

    const isMounted = useIsMounted();

    return <Dialog
        open={isModalVisible}
        onClose={() => {
            setModalVisible(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="sm"
        fullWidth
    >

        <DialogTitle>
            {
                default_values ? "Update Training Video" : "Add Training Video"
            }
        </DialogTitle>


        <Formik
            initialValues={{
                title: (default_values && default_values.title) || "",
                title_ko: (default_values && default_values.title_ko) || "",
                title_es: (default_values && default_values.title_es) || "",
                title_ja: (default_values && default_values.title_ja) || "",
                title_zh: (default_values && default_values.title_zh) || "",
                description: (default_values && default_values.description) || "",
                description_ko: (default_values && default_values.description_ko) || "",
                description_es: (default_values && default_values.description_es) || "",
                description_ja: (default_values && default_values.description_ja) || "",
                description_zh: (default_values && default_values.description_zh) || "",
                is_enabled: (default_values && default_values.is_enabled) || false,
                training_video_file:(default_values && default_values.training_video_file) || "",
                training_video_thumbnail_file:(default_values && default_values.training_video_thumbnail_file) || "",
                progress:0,
                is_movement: (default_values && default_values.is_movement) || false,
            }}
            onSubmit={(values: any, helpers) => {
                helpers.setSubmitting(true);
                if (!default_values) {
                    new TrainingVideosAPIs().create(values,(e: any) => {
                        try {
                            const percentage = Math.round(e.loaded / e.total * 100);
                            helpers.setFieldValue("progress",percentage);
                        } catch (e) {

                        }
                    }).then((response) => {

                        if (isMounted.current) {
                            helpers.setSubmitting(false);
                            if (UserAPIs.hasError(response)) {
                                if (HandleErrors(response, helpers)) {
                                }
                            } else {
                                trainingVideoCreated && trainingVideoCreated()
                            }
                        }
                    })
                } else {
                    new TrainingVideosAPIs().edit(default_values.uid, values,(e: any) => {
                        try {
                            const percentage = Math.round(e.loaded / e.total * 100);
                            helpers.setFieldValue("progress",percentage);
                        } catch (e) {

                        }
                    }).then((response) => {
                        if (isMounted.current) {
                            helpers.setSubmitting(false);
                            if (UserAPIs.hasError(response)) {
                                if (HandleErrors(response, helpers)) {
                                }
                            } else {
                                trainingVideoCreated && trainingVideoCreated()
                            }
                        }
                    })
                }

            }}
            validationSchema={
                yup.object().shape({
                    title: yup
                        .string()
                        .min(2, ({min}) => `Title must be at least ${min} characters`)
                        .required("Title is required"),
                    title_ko: yup
                        .string()
                        .min(2,({min})=>`Title must be at least ${min} characters in Korean`)
                    ,
                    title_es: yup
                        .string()
                        .min(2,({min})=>`Title must be at least ${min} characters in Spanish`)
                    ,
                    title_ja: yup
                        .string()
                        .min(2,({min})=>`Title must be at least ${min} characters in Japanese`)
                    ,
                    title_zh: yup
                        .string()
                        .min(2,({min})=>`Title must be at least ${min} characters in Chinese`)
                    ,
                    description: yup
                        .string()
                        .min(2, ({min}) => `Description must be at least ${min} characters`)
                        .required("Description is required"),
                    description_ko: yup
                        .string()
                        .min(2,({min})=>`Description must be at least ${min} characters in Korean`),
                    description_es: yup
                        .string()
                        .min(2,({min})=>`Description must be at least ${min} characters in Spanish`),
                    description_ja: yup
                        .string()
                        .min(2,({min})=>`Description must be at least ${min} characters in Japanese`),
                    description_zh: yup
                        .string()
                        .min(2,({min})=>`Description must be at least ${min} characters in Chinese`),
                })}
        >
            {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  errors,
                  touched,
                  values,
                  setFieldValue,
                  isSubmitting
              }) => {
                return <form onSubmit={handleSubmit}><DialogContent>

                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <TextField
                                value={values.title}
                                onChange={handleChange}
                                margin="normal"
                                label="Title *"
                                type="text"
                                name="title"
                                fullWidth
                                helperText={touched && touched.title && errors && errors.title}
                                error={!!(touched && touched.title && errors && errors.title)}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField fullWidth value={values.title_ko}
                                       onChange={handleChange}
                                       margin="normal"
                                       type={"text"}
                                       name={'title_ko'}
                                       label="Title in Korean (optional)"
                                       helperText={touched && touched.title_ko && errors && errors.title_ko}
                                       error={!!(touched && touched.title_ko && errors && errors.title_ko)}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                fullWidth value={values.title_es}
                                onChange={handleChange}
                                margin="normal"
                                type={"text"}
                                name={'title_es'}
                                label="Title in Spanish (optional)"
                                helperText={touched && touched.title_es && errors && errors.title_es}
                                error={!!(touched && touched.title_es && errors && errors.title_es)}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                fullWidth value={values.title_ja}
                                onChange={handleChange}
                                margin="normal"
                                name={'title_ja'}
                                type={"text"}
                                label="Title in Japanese (optional)"
                                helperText={touched && touched.title_ja && errors && errors.title_ja}
                                error={!!(touched && touched.title_ja && errors && errors.title_ja)}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                fullWidth value={values.title_zh}
                                onChange={handleChange}
                                name={'title_zh'}
                                type={"text"}
                                label="Title in Chinese (optional)"
                                helperText={touched && touched.title_zh && errors && errors.title_zh}
                                error={!!(touched && touched.title_zh && errors && errors.title_zh)}

                            />
                        </Grid>

                        <Grid item md={12}>
                            <TextField
                                value={values.description}
                                onChange={handleChange}
                                margin="normal"
                                label="Description *"
                                type="text"
                                name="description"
                                fullWidth
                                helperText={touched && touched.description && errors && errors.description}
                                error={!!(touched && touched.description && errors && errors.description)}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                value={values.description_ko}
                                onChange={handleChange}
                                margin="normal"
                                label={"Description in Korean (optional)"}
                                name="description_ko"
                                fullWidth
                                helperText={touched && touched.description_ko && errors && errors.description_ko}
                                error={!!(touched && touched.description_ko && errors && errors.description_ko)}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                value={values.description_es}
                                onChange={handleChange}
                                margin="normal"
                                label={"Description in Spanish (optional)"}
                                name="description_es"
                                fullWidth
                                helperText={touched && touched.description_es && errors && errors.description_es}
                                error={!!(touched && touched.description_es && errors && errors.description_es)}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                value={values.description_ja}
                                onChange={handleChange}
                                margin="normal"
                                label={"Description in Japan (optional)"}
                                name="description_ja"
                                fullWidth
                                helperText={touched && touched.description_ja && errors && errors.description_ja}
                                error={!!(touched && touched.description_ja && errors && errors.description_ja)}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                value={values.description_zh}
                                onChange={handleChange}
                                margin="normal"
                                label={"Description in Chinese (optional)"}
                                name="description_zh"
                                fullWidth
                                helperText={touched && touched.description_zh && errors && errors.description_zh}
                                error={!!(touched && touched.description_zh && errors && errors.description_zh)}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                value={values.thumbnail_path}
                                onChange={(e:any)=>{
                                    if(e.target.files && e.target.files.length>0)
                                    {
                                        setFieldValue("training_video_thumbnail_file",e.target.files[0]);
                                    }
                                }}
                                margin="normal"
                                label="Thumbnail"
                                type="file"
                                name="training_video_thumbnail_file"
                                fullWidth
                                helperText={touched && touched.training_video_thumbnail_file && errors && errors.training_video_thumbnail_file}
                                error={!!(touched && touched.training_video_thumbnail_file&& errors && errors.training_video_thumbnail_file)}
                            />
                            {!!values.progress && <LinearProgress variant="determinate" value={values.progress} />}
                        </Grid>

                        <Grid item md={12}>
                            <TextField
                                value={values.video_path}
                                onChange={(e:any)=>{
                                    if(e.target.files && e.target.files.length>0)
                                    {
                                        setFieldValue("training_video_file",e.target.files[0]);
                                    }
                                }}
                                margin="normal"
                                label="Upload "
                                type="file"
                                name="training_video_file"
                                fullWidth
                                helperText={touched && touched.training_video_file && errors && errors.training_video_file}
                                error={!!(touched && touched.training_video_file && errors && errors.training_video_file)}
                            />
                            {!!values.progress && <LinearProgress variant="determinate" value={values.progress} />}
                        </Grid>
                        <Grid item md={12} sm={12}>
                            <FormControlLabel control={<Checkbox
                                value={false}
                                checked={values.is_enabled}
                                onChange={(event, checked) => {
                                    setFieldValue("is_enabled", checked)
                                }}
                                title=""
                            />} label="Is Enabled?"/>
                        </Grid>
                        <Grid item md={12} sm={12}>
                            <FormControlLabel control={<Checkbox
                                value={false}
                                checked={values.is_movement}
                                onChange={(event, checked) => {
                                    setFieldValue("is_movement", checked)
                                }}
                                title=""
                            />} label="Is movement video?"/>
                        </Grid>
                    </Grid>

                </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            setModalVisible(false)
                        }}>Cancel</Button>
                        <Button
                            disable={isSubmitting}
                            type={"submit"}>Save</Button>
                    </DialogActions>

                </form>
            }}
        </Formik>


    </Dialog>
}

export default NewTrainingVideo;
