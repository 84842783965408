import React, {Dispatch, useContext, useState} from "react";
import {AppBar, IconButton, InputBase, Menu, Toolbar} from "@material-ui/core";
import {
    ArrowBack as ArrowBackIcon,
    Menu as MenuIcon,
    Person as AccountIcon,
    Search as SearchIcon,
} from "@material-ui/icons";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import {Typography} from "../Wrappers";


// context
import {toggleSidebar, useLayoutDispatch, useLayoutState,} from "../../context/LayoutContext";
import {iStoreAction} from "../../reducer";
import {AppDispatchContext, AppStateContext} from "../../App";
import UserAPIs from "../../apis/user.apis";
import useIsMounted from "ismounted";

export default function Header(props: any) {
    const classes = useStyles();

    const {loggedInUser} = useContext(AppStateContext);
    const dispatch: Dispatch<iStoreAction> = useContext(AppDispatchContext);
    // global
    const layoutState = useLayoutState();
    const layoutDispatch = useLayoutDispatch();

    // local

    const [profileMenu, setProfileMenu] = useState(null);
    const [isSearchOpen, setSearchOpen] = useState(false);
    const isMounted = useIsMounted();
    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <IconButton
                    color="inherit"
                    onClick={() => toggleSidebar(layoutDispatch)}
                    className={classNames(
                        classes.headerMenuButtonSandwich,
                        classes.headerMenuButtonCollapse,
                    )}
                >
                    {layoutState.isSidebarOpened ? (
                        <ArrowBackIcon
                            classes={{
                                root: classNames(
                                    classes.headerIcon,
                                    classes.headerIconCollapse,
                                ),
                            }}
                        />
                    ) : (
                        <MenuIcon
                            classes={{
                                root: classNames(
                                    classes.headerIcon,
                                    classes.headerIconCollapse,
                                ),
                            }}
                        />
                    )}
                </IconButton>
                <Typography variant="h6" weight="medium" className={classes.logotype}>
                    Fitapp Admin
                </Typography>
                <div className={classes.grow}/>
                <div
                    className={classNames(classes.search, {
                        [classes.searchFocused]: isSearchOpen,
                    })}
                >
                    <div
                        className={classNames(classes.searchIcon, {
                            [classes.searchIconOpened]: isSearchOpen,
                        })}
                        onClick={() => setSearchOpen(!isSearchOpen)}
                    >
                        <SearchIcon classes={{root: classes.headerIcon}}/>
                    </div>
                    <InputBase
                        placeholder="Search…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                    />
                </div>
                <IconButton
                    aria-haspopup="true"
                    color="inherit"
                    className={classes.headerMenuButton}
                    aria-controls="profile-menu"
                    onClick={(e: any) => setProfileMenu(e.currentTarget)}
                >
                    <AccountIcon classes={{root: classes.headerIcon}}/>
                </IconButton>

                <Menu
                    id="profile-menu"
                    open={Boolean(profileMenu)}
                    anchorEl={profileMenu}
                    onClose={() => setProfileMenu(null)}
                    className={classes.headerMenu}
                    classes={{paper: classes.profileMenu}}
                    disableAutoFocusItem
                >
                    <div className={classes.profileMenuUser}>
                        <Typography variant="h4" weight="medium">
                            {
                                loggedInUser && loggedInUser.name
                            }
                        </Typography>
                    </div>
                    <div className={classes.profileMenuUser}>
                        <Typography
                            className={classes.profileMenuLink}
                            color="primary"
                            onClick={() => {
                                new UserAPIs().logout().then((res) => {
                                    if (isMounted.current) {
                                        if (!UserAPIs.hasError(res)) {
                                            dispatch({type: "logout"})
                                        }
                                    }
                                })
                            }}
                        >
                            Sign Out
                        </Typography>
                    </div>
                </Menu>
            </Toolbar>
        </AppBar>
    );
}
