import React, {useContext} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    FormControl, Select, FormHelperText, Checkbox, FormControlLabel, LinearProgress,
} from "@material-ui/core";
import {FieldArray, Formik} from "formik";
import * as yup from "yup";
import useStyles from "./style"
import {Button} from "../../components/Wrappers";
import UserAPIs from "../../apis/user.apis";
import {HandleErrors} from "../../helper/form.helper";
import useIsMounted from "ismounted"
import TrainingSessionsAPIs, {iTrainingSession} from "../../apis/trainingSessions.apis";
import MovementAPIs, {iMovement} from "../../apis/movements.apis";
import {Typography} from "@mui/material";
import {getNestedValue} from "../../helper/common";
import {Loading} from "../../App";

const NewMovement = ({default_values, isModalVisible, setModalVisible, movementCreated}: {
    default_values?: iMovement, isModalVisible: boolean,
    setModalVisible: any, movementCreated: any
}) => {


    const isMounted = useIsMounted();

    return <Dialog
        open={isModalVisible}
        onClose={() => {
            setModalVisible(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="sm"
        fullWidth
    >

        <DialogTitle>
            {
                default_values ? "Update Training Session" : "Add Movement"
            }
        </DialogTitle>


        <Formik
            initialValues={{
                title: (default_values && default_values.title) || "",
                title_ko: (default_values && default_values.title_ko) || "",
                title_es: (default_values && default_values.title_es) || "",
                title_ja: (default_values && default_values.title_ja) || "",
                title_zh: (default_values && default_values.title_zh) || "",
                code: (default_values && default_values.code) || "",
                description: (default_values && default_values.description) || "",
                description_ko: (default_values && default_values.description_ko) || "",
                description_es: (default_values && default_values.description_es) || "",
                description_ja: (default_values && default_values.description_ja) || "",
                description_zh: (default_values && default_values.description_zh) || "",
                is_enabled: (default_values && default_values.is_enabled) || false,
                level: (default_values && default_values.level) || "beginner",
                accessories: (default_values && default_values.accessories) || "",
                accessories_ko: (default_values && default_values.accessories_ko) || "",
                accessories_es: (default_values && default_values.accessories_es) || "",
                accessories_ja: (default_values && default_values.accessories_ja) || "",
                accessories_zh: (default_values && default_values.accessories_zh) || "",
                movement_video_file: "",
                movement_video_thumbnail_file: "",
                sets: (default_values && default_values.sets) || [
                    {
                        reps: "",
                        weight: "",
                        time_minutes: ""
                    },
                    {
                        reps: "",
                        weight: "",
                        time_minutes: ""
                    },
                    {
                        reps: "",
                        weight: "",
                        time_minutes: ""
                    },
                ]
            }}
            onSubmit={(values: any, helpers) => {
                helpers.setSubmitting(true);
                if (!default_values) {
                    new MovementAPIs().create(values).then((response) => {
                        if (isMounted.current) {
                            helpers.setSubmitting(false);
                            if (MovementAPIs.hasError(response)) {
                                if (HandleErrors(response, helpers)) {
                                }
                            } else {
                                movementCreated && movementCreated()
                            }
                        }
                    })
                } else {
                    new MovementAPIs().edit(default_values.uid, values).then((response) => {
                        if (isMounted.current) {
                            helpers.setSubmitting(false);
                            if (MovementAPIs.hasError(response)) {
                                if (HandleErrors(response, helpers)) {
                                }
                            } else {
                                movementCreated && movementCreated()
                            }
                        }
                    })
                }

            }}
            validationSchema={
                !!default_values ?
                    yup.object().shape({
                        title: yup
                            .string()
                            .min(2, ({min}) => `Title must be at least ${min} characters`)
                            .required("Title is required"),
                        code: yup
                            .string()
                            .min(2, ({min}) => `Code must be at least ${min} characters`)
                            .required("Code is required"),
                        description: yup
                            .string()
                            .min(2, ({min}) => `Description must be at least ${min} characters`)
                            .required("Description is required"),
                        level: yup
                            .string()
                            .required("This is required"),
                        accessories: yup
                            .string()
                            .required("This is required"),
                        sets: yup
                            .array(yup.object({
                                reps: yup
                                    .string()
                                    .required("Reps are required"),
                                weight: yup
                                    .string()
                                    .required("Weight is required"),
                                time_minutes: yup
                                    .string()
                                    .required("Minutes are required"),
                            })),
                    }) :
                    yup.object().shape({
                        title: yup
                            .string()
                            .min(2, ({min}) => `Title must be at least ${min} characters`)
                            .required("Title is required"),
                        title_ko: yup
                            .string()
                            .min(2, ({min}) => `Title must be at least ${min} characters in Korean`)
                        ,
                        title_es: yup
                            .string()
                            .min(2, ({min}) => `Title must be at least ${min} characters in Spanish`)
                        ,
                        title_ja: yup
                            .string()
                            .min(2, ({min}) => `Title must be at least ${min} characters in Japanese`)
                        ,
                        title_zh: yup
                            .string()
                            .min(2, ({min}) => `Title must be at least ${min} characters in Chinese`)
                        ,
                        description: yup
                            .string()
                            .min(2, ({min}) => `Description must be at least ${min} characters`)
                            .required("Description is required"),
                        description_ko: yup
                            .string()
                            .min(2, ({min}) => `Description must be at least ${min} characters in Korean`),
                        description_es: yup
                            .string()
                            .min(2, ({min}) => `Description must be at least ${min} characters in Spanish`),
                        description_ja: yup
                            .string()
                            .min(2, ({min}) => `Description must be at least ${min} characters in Japanese`),
                        description_zh: yup
                            .string()
                            .min(2, ({min}) => `Description must be at least ${min} characters in Chinese`),
                        level: yup
                            .string()
                            .required("This is required"),
                        movement_video_file: yup
                            .mixed()
                            .required("This is required"),
                        movement_video_thumbnail_file: yup
                            .mixed()
                            .required("This is required"),
                        accessories: yup
                            .string()
                            .required("This is required"),
                        accessories_ko: yup
                            .string(),
                        accessories_es: yup
                            .string(),
                        accessories_zh: yup
                            .string(),
                        accessories_ja: yup
                            .string(),
                        sets: yup
                            .array(yup.object({
                                reps: yup
                                    .string()
                                    .required("Reps are required"),
                                weight: yup
                                    .string()
                                    .required("Weight is required"),
                                time_minutes: yup
                                    .string()
                                    .required("Minutes are required"),
                            })),
                        code: yup
                            .string()
                            .min(2, ({min}) => `Code must be at least ${min} characters`)
                            .required("Code is required"),
                    })
            }
        >
            {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  errors,
                  touched,
                  values,
                  setFieldValue,
                  isSubmitting
              }) => {
                return <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <Grid container spacing={2}>

                            <Grid item md={12}>
                                <TextField
                                    value={values.title}
                                    onChange={handleChange}
                                    margin="normal"
                                    label="Title *"
                                    type="text"
                                    name="title"
                                    fullWidth
                                    helperText={touched && touched.title && errors && errors.title}
                                    error={!!(touched && touched.title && errors && errors.title)}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField fullWidth value={values.title_ko}
                                           onChange={handleChange}
                                           margin="normal"
                                           type={"text"}
                                           name={'title_ko'}
                                           label="Title in Korean (optional)"
                                           helperText={touched && touched.title_ko && errors && errors.title_ko}
                                           error={!!(touched && touched.title_ko && errors && errors.title_ko)}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    fullWidth value={values.title_es}
                                    onChange={handleChange}
                                    margin="normal"
                                    type={"text"}
                                    name={'title_es'}
                                    label="Title in Spanish (optional)"
                                    helperText={touched && touched.title_es && errors && errors.title_es}
                                    error={!!(touched && touched.title_es && errors && errors.title_es)}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    fullWidth value={values.title_zh}
                                    onChange={handleChange}
                                    name={'title_zh'}
                                    type={"text"}
                                    label="Title in Chinese (optional)"
                                    helperText={touched && touched.title_zh && errors && errors.title_zh}
                                    error={!!(touched && touched.title_zh && errors && errors.title_zh)}

                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    fullWidth value={values.title_ja}
                                    onChange={handleChange}
                                    margin="normal"
                                    name={'title_ja'}
                                    type={"text"}
                                    label="Title in Japanese (optional)"
                                    helperText={touched && touched.title_ja && errors && errors.title_ja}
                                    error={!!(touched && touched.title_ja && errors && errors.title_ja)}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    value={values.code}
                                    onChange={handleChange}
                                    margin="normal"
                                    label="Code *"
                                    type="text"
                                    name="code"
                                    fullWidth
                                    helperText={touched && touched.code && errors && errors.code}
                                    error={!!(touched && touched.code && errors && errors.code)}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    value={values.description}
                                    onChange={handleChange}
                                    margin="normal"
                                    label="Description *"
                                    type="text"
                                    name="description"
                                    multiline
                                    fullWidth
                                    helperText={touched && touched.description && errors && errors.description}
                                    error={!!(touched && touched.description && errors && errors.description)}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    value={values.description_ko}
                                    onChange={handleChange}
                                    margin="normal"
                                    label={"Description in Korean (optional)"}
                                    name="description_ko"
                                    fullWidth
                                    helperText={touched && touched.description_ko && errors && errors.description_ko}
                                    error={!!(touched && touched.description_ko && errors && errors.description_ko)}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    value={values.description_es}
                                    onChange={handleChange}
                                    margin="normal"
                                    label={"Description in Spanish (optional)"}
                                    name="description_es"
                                    fullWidth
                                    helperText={touched && touched.description_es && errors && errors.description_es}
                                    error={!!(touched && touched.description_es && errors && errors.description_es)}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    value={values.description_zh}
                                    onChange={handleChange}
                                    margin="normal"
                                    label={"Description in Chinese (optional)"}
                                    name="description_zh"
                                    fullWidth
                                    helperText={touched && touched.description_zh && errors && errors.description_zh}
                                    error={!!(touched && touched.description_zh && errors && errors.description_zh)}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    value={values.description_ja}
                                    onChange={handleChange}
                                    margin="normal"
                                    label={"Description in Japanese (optional)"}
                                    name="description_ja"
                                    fullWidth
                                    helperText={touched && touched.description_ja && errors && errors.description_ja}
                                    error={!!(touched && touched.description_ja && errors && errors.description_ja)}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    value={values.accessories}
                                    onChange={handleChange}
                                    margin="normal"
                                    label="Accessories *"
                                    type="text"
                                    name="accessories"
                                    fullWidth
                                    helperText={touched && touched.accessories && errors && errors.accessories}
                                    error={!!(touched && touched.accessories && errors && errors.accessories)}
                                />
                            </Grid>

                            <Grid item md={12}>
                                <TextField
                                    value={values.accessories_ko}
                                    onChange={handleChange}
                                    margin="normal"
                                    label="Accessories in Korean (optional)"
                                    type="text"
                                    name="accessories_ko"
                                    fullWidth
                                    helperText={touched && touched.accessories_ko && errors && errors.accessories_ko}
                                    error={!!(touched && touched.accessories_ko && errors && errors.accessories_ko)}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    value={values.accessories_es}
                                    onChange={handleChange}
                                    margin="normal"
                                    label="Accessories in Spanish (optional)"
                                    type="text"
                                    name="accessories_es"
                                    fullWidth
                                    helperText={touched && touched.accessories_es && errors && errors.accessories_es}
                                    error={!!(touched && touched.accessories_es && errors && errors.accessories_es)}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    value={values.accessories_zh}
                                    onChange={handleChange}
                                    margin="normal"
                                    label="Accessories in Chinese (optional)"
                                    type="text"
                                    name="accessories_zh"
                                    fullWidth
                                    helperText={touched && touched.accessories_zh && errors && errors.accessories_zh}
                                    error={!!(touched && touched.accessories_zh && errors && errors.accessories_zh)}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    value={values.accessories_ja}
                                    onChange={handleChange}
                                    margin="normal"
                                    label="Accessories in Japanese (optional)"
                                    type="text"
                                    name="accessories_ja"
                                    fullWidth
                                    helperText={touched && touched.accessories_ja && errors && errors.accessories_ja}
                                    error={!!(touched && touched.accessories_ja && errors && errors.accessories_ja)}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    select
                                    label="Level *"
                                    value={values.level}
                                    onChange={handleChange}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    variant="filled"
                                    name="level"
                                    fullWidth
                                    helperText={touched && touched.level && errors && errors.level}
                                    error={!!(touched && touched.level && errors && errors.level)}
                                >
                                    {[
                                        {
                                            label: "Beginner",
                                            value: "beginner"
                                        },
                                        {
                                            label: "Intermediate",
                                            value: "intermediate"
                                        },
                                        {
                                            label: "Advanced",
                                            value: "advanced"
                                        }
                                    ].map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item md={12}>
                                <Typography variant={"caption"}>
                                    Upload Movement Thumbnail *
                                </Typography>

                                <TextField
                                    onChange={(e: any) => {
                                        if (e.target.files && e.target.files.length > 0) {
                                            setFieldValue("movement_video_thumbnail_file", e.target.files[0]);
                                        }
                                    }}
                                    inputProps={{
                                        accept: ".jpg,.png"
                                    }}
                                    margin="normal"
                                    label="Upload "
                                    type="file"
                                    name="movement_video_thumbnail_file"
                                    fullWidth
                                    helperText={touched && touched.movement_video_thumbnail_file && errors && errors.movement_video_thumbnail_file}
                                    error={!!(touched && touched.movement_video_thumbnail_file && errors && errors.movement_video_thumbnail_file)}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <Typography variant={"caption"}>
                                    Upload Movement Video *
                                </Typography>
                                <TextField
                                    onChange={(e: any) => {
                                        if (e.target.files && e.target.files.length > 0) {
                                            setFieldValue("movement_video_file", e.target.files[0]);
                                        }
                                    }}

                                    inputProps={{
                                        accept: ".mp4"
                                    }}
                                    label="Upload "
                                    type="file"
                                    name="movement_video_file"
                                    fullWidth
                                    helperText={touched && touched.movement_video_file && errors && errors.movement_video_file}
                                    error={!!(touched && touched.movement_video_file && errors && errors.movement_video_file)}
                                />
                            </Grid>
                            <Grid md={12} item>
                                <FieldArray name={"sets"}>
                                    {
                                        props => {
                                            return <Grid container>

                                                {
                                                    values.sets && values.sets.map((v: any, index: any) => {
                                                        return <Grid item md={12} key={index}>

                                                            <Grid container spacing={2}>
                                                                <Grid item md={4}>
                                                                    <Typography variant={"caption"}>
                                                                        Set {index + 1}
                                                                    </Typography>
                                                                    <TextField
                                                                        value={getNestedValue(values, `sets[${index}].reps`)}
                                                                        onChange={handleChange}
                                                                        label="Reps *"
                                                                        type="number"
                                                                        margin="normal"
                                                                        name={`sets[${index}].reps`}
                                                                        fullWidth
                                                                        helperText={!!getNestedValue(touched, `sets[${index}].reps`) && getNestedValue(errors, `sets[${index}].reps`)}
                                                                        error={!!getNestedValue(touched, `sets[${index}].reps`) && !!getNestedValue(errors, `sets[${index}].reps`)}
                                                                    />
                                                                </Grid>
                                                                <Grid item md={4}>
                                                                    <Typography variant={"caption"}>
                                                                        &nbsp;
                                                                    </Typography>
                                                                    <TextField
                                                                        value={getNestedValue(values, `sets[${index}].weight`)}
                                                                        onChange={handleChange}
                                                                        margin="normal"
                                                                        label="Weight *"
                                                                        type="number"
                                                                        name={`sets[${index}].weight`}
                                                                        fullWidth
                                                                        helperText={!!getNestedValue(touched, `sets[${index}].weight`) && getNestedValue(errors, `sets[${index}].weight`)}
                                                                        error={!!getNestedValue(touched, `sets[${index}].weight`) && !!getNestedValue(errors, `sets[${index}].weight`)}
                                                                    />
                                                                </Grid>
                                                                <Grid item md={4}>
                                                                    <Typography variant={"caption"}>
                                                                        &nbsp;
                                                                    </Typography>
                                                                    <TextField
                                                                        value={getNestedValue(values, `sets[${index}].time_minutes`)}
                                                                        onChange={handleChange}
                                                                        margin="normal"
                                                                        label="Sec *"
                                                                        type="number"
                                                                        name={`sets[${index}].time_minutes`}
                                                                        fullWidth
                                                                        helperText={!!getNestedValue(touched, `sets[${index}].time_minutes`) && getNestedValue(errors, `sets[${index}].time_minutes`)}
                                                                        error={!!getNestedValue(touched, `sets[${index}].time_minutes`) && !!getNestedValue(errors, `sets[${index}].time_minutes`)}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    })
                                                }
                                            </Grid>
                                        }
                                    }
                                </FieldArray>
                            </Grid>
                            <Grid item md={12}>
                                <FormControlLabel control={<Checkbox
                                    value={false}
                                    checked={values.is_enabled}
                                    onChange={(event, checked) => {
                                        setFieldValue("is_enabled", checked)
                                    }}
                                    title=""
                                />} label="Is Enabled?"/>
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button
                            disable={isSubmitting}
                            onClick={() => {
                                setModalVisible(false)
                            }}>Cancel</Button>
                        <Button
                            disable={isSubmitting}
                            type={"submit"}>
                            {
                                isSubmitting ? <><Loading/> Saving...</> : "Save"
                            }
                        </Button>
                    </DialogActions>

                </form>
            }}
        </Formik>


    </Dialog>
}

export default NewMovement;
