import axios from 'axios';
import {iApiBasicResponse, iCsrfToken, iCsrfTokenResponse} from "../types/all";
import {NotificationDispatch} from "../App";


export default class BaseAPIs {
    static csrfToken: iCsrfToken = {
        token: "dummy_expired_token",
        expiry: new Date(0)
    };
    timeout = (ms: number) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    static hasError = (response: iApiBasicResponse, notificationDispatcher?: NotificationDispatch) => {
        notificationDispatcher && notificationDispatcher.pushNotification(response);
        if (response && response.statusCode && response.statusCode >= 400) return true;
        if (response && response.validation_errors) return true;
    };
    static hasErrorNotification = (response: iApiBasicResponse) => {
        return response && response.statusCode && response.statusCode >= 400;

    };
    handleCatch = (error: any) => {

        if (error && error.response && error.response.status && error.response.data) {
            return {
                ...error.response.data, statusCode: error.response.status
            }
        } else if (error instanceof axios.Cancel) {
            return {statusCode: 999, message: undefined};
        } else {
            return {statusCode: 999, message: "Network Error"};
        }
    }

    static getError = (response: iApiBasicResponse): string => {
        if (response.message && typeof response.message === "string") return response.message;
        if (!response.validation_errors) return BaseAPIs.getErrorFromStatusCode(response);
        if (response.validation_errors.csrf) return BaseAPIs.getErrorString(response.validation_errors.csrf);
        if (response.validation_errors.network) return BaseAPIs.getErrorString(response.validation_errors.network);

        if (response.validation_errors.email) return BaseAPIs.getErrorString(response.validation_errors.email);
        if (response.validation_errors.password) return BaseAPIs.getErrorString(response.validation_errors.password);

        if (response.validation_errors.name) return BaseAPIs.getErrorString(response.validation_errors.name);
        return "Unknown error!";
    };

    static getErrorFromStatusCode = (response: iApiBasicResponse): string => {
        switch (response.statusCode) {
            case 400:
                return "Request error: Bad Request";
            case 401:
                return "Request error: Unauthorized";
            case 402:
                return "Request error: Payment Required";
            case 403:
                return "Request error: Forbidden";
            case 404:
                return "Request error: Not Found";
            case 405:
                return "Request error: Method Not Allowed";
            case 500:
                return "Server error: Internal error";
            case 501:
                return "Server error: Not Implemented";
            case 502:
                return "Server error: Bad Gateway";
            case 503:
                return "Server error: Service Unavailable";
            case 504:
                return "Server error: Gateway Timeout";
            default:
                return "Unknown error";
        }
    };


    private static getErrorString(error: string | Array<string>) {
        if (typeof error === "string") return error;
        else return error.join(" ");
    }

    getApiBaseURL = () => {
       return "https://sprintwheel.com";
       //  return "http://localhost:8000";
    };

    getCsrfToken = async (): Promise<string> => {
        return this.fetchCsrfToken().then((r: iCsrfTokenResponse) => {
            if (r) {
                if (BaseAPIs.hasError(r) || !r.token) {
                    return "";
                } else {
                    return r.token;
                }
            }
            return '';

        })
    };

    fetchCsrfToken = (): Promise<iCsrfTokenResponse> => {
        return axios
            .get(this.getApiBaseURL() + "/_api/v1/csrf-token", {
                withCredentials: true,
            },)
            .then((res): iCsrfTokenResponse => ({statusCode: res.status, ...res.data}))
            .catch((): any => {
            });
    };
    ipLocation = (): Promise<any> => {
        return axios
            .get(this.getApiBaseURL() + "/_api/v1/ip-location", {
                withCredentials: true,
            },)
            .then((res): any => ({statusCode: res.status, ...res.data}))
            .catch((): any => {
            });
    };

}
