import {makeStyles} from "@material-ui/styles";

export default makeStyles((theme: any) => ({
    pageTitleContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    typo: {
        color: theme.palette.text.hint,
    },
    button: {
        boxShadow: theme.customShadows.widget,
        textTransform: "none",
        "&:active": {
            boxShadow: theme.customShadows.widgetWide,
        },
    },
}));
