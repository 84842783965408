import React, {lazy, Suspense} from "react";
import {Redirect, Route, Switch, withRouter,} from "react-router-dom";
import classnames from "classnames";
import {Box, CircularProgress, IconButton, Link} from '@material-ui/core'
import Icon from '@mdi/react'

//icons
import {mdiFacebook as FacebookIcon, mdiGithub as GithubIcon, mdiTwitter as TwitterIcon,} from '@mdi/js'

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";
// context
import {useLayoutState} from "../../context/LayoutContext";
import TrainingVideos from "../../pages/TrainingVideos/TrainingVideos";
import Movements from "../../pages/Movements/Movements";

const Dashboard = lazy(() => import('../../pages/dashboard'));
const Logs = lazy(() => import('../../pages/Logs/Logs'));
const Customer = lazy(() => import('../../pages/Customer/Customer'));
const TrainingSessionsRoutes = lazy(() => import('../../pages/TrainingSessions/TrainingSessionRoutes'));


function Layout(props: any) {
    const classes = useStyles();
    const layoutState = useLayoutState();

    return (
        <div className={classes.root}>
            <>
                <Header history={props.history}/>
                <Sidebar/>
                <div
                    className={classnames(classes.content, {
                        [classes.contentShift]: layoutState.isSidebarOpened,
                    })}
                >
                    <div className={classes.fakeToolbar}/>
                    <Suspense fallback={<CircularProgress/>}>
                        <Switch>
                            <Route path="/dashboard" component={Dashboard}/>
                            <Route path="/customer" component={Customer}/>
                            <Route path="/training-sessions" component={TrainingSessionsRoutes}/>
                            <Route path="/movements" component={Movements}/>
                            <Route path="/training-videos" component={TrainingVideos}/>
                            <Redirect to={"/dashboard"}/>
                        </Switch>
                    </Suspense>
                    <Box
                        mt={5}
                        width={"100%"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent="space-between"
                    >
                        <div>
                            <Link
                                color={'primary'}
                                href={'https://sprintwheel.com/'}
                                target={'_blank'}
                                className={classes.link}
                            >
                                Fitapp
                            </Link>
                            <Link
                                color={'primary'}
                                href={'https://sprintwheel.com/about'}
                                target={'_blank'}
                                className={classes.link}
                            >
                                About Us
                            </Link>
                            <Link
                                color={'primary'}
                                href={'https://sprintwheel.com/blog'}
                                target={'_blank'}
                                className={classes.link}
                            >
                                Blog
                            </Link>
                        </div>
                        <div>
                            <Link
                                href={'https://www.facebook.com/sprintwheel'}
                                target={'_blank'}
                            >
                                <IconButton aria-label="facebook">
                                    <Icon
                                        path={FacebookIcon}
                                        size={1}
                                        color="#6E6E6E99"
                                    />
                                </IconButton>
                            </Link>
                            <Link
                                href={'https://twitter.com/sprintwheel'}
                                target={'_blank'}
                            >
                                <IconButton aria-label="twitter">
                                    <Icon
                                        path={TwitterIcon}
                                        size={1}
                                        color="#6E6E6E99"
                                    />
                                </IconButton>
                            </Link>
                            <Link
                                href={'https://github.com/sprintwheel'}
                                target={'_blank'}
                            >
                                <IconButton
                                    aria-label="github"
                                    style={{marginRight: -12}}
                                >
                                    <Icon
                                        path={GithubIcon}
                                        size={1}
                                        color="#6E6E6E99"
                                    />
                                </IconButton>
                            </Link>
                        </div>
                    </Box>
                </div>
            </>
        </div>
    );
}

export default withRouter(Layout);
