import qs from "qs";
import {iListingQuery} from "../types/all";

export default function QueryParser(location: any, _default_query?: iListingQuery): iListingQuery {
    let default_query: iListingQuery = {
        sort_order: "created_at",
        sort_direction: "desc",
        per_page: 10, ..._default_query
    };
    console.log(location);
    let search = location.search.replace("?", "");
    let parsed = qs.parse(search)
    if (parsed.page) {
        let page = 1;
        try {
            page = parseInt(parsed.page.toString())
        } finally {
            default_query["page"] = page
        }
    }
    return default_query;
}



export const getPageQuery = (page: number): string => {
    return qs.stringify({page: page});
}
