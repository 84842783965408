import React from "react";

// styles
import useStyles from "./styles";

// components
import {Typography} from "../Wrappers";
import {Grid, IconButton} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";

export default function PageTitle(props: any) {
    const classes = useStyles();

    return (
        <Grid container justifyContent="space-between" spacing={4}>
            <Grid item>
                <Grid container spacing={1} alignItems="center">
                    {
                        props.backButton && <Grid item>
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    props.backButtonClick && props.backButtonClick();
                                }}
                            >
                                <ArrowBack/>
                            </IconButton>
                        </Grid>
                    }
                    <Grid item>
                        <Typography className={classes.typo} variant="h1" size="sm">
                            {props.title}
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item>
                {props.button && props.button}
            </Grid>
        </Grid>
    );
}
