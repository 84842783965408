import {Reducer} from "redux";
import {iApiBasicResponse, iCollectionElement, iListingQuery, User} from "./types/all";
import {getPageQuery} from "./components/QueryParser";


export interface State extends iMaster {
    loggedInUser?: User;
    logging?: boolean;
    notifications: any[];
}

export interface iMaster extends iApiBasicResponse {
    countries: iCountry[] | undefined,
    activity_level: string[] | undefined,
    primary_goal: string[] | undefined,
}

export interface iCountry extends iCollectionElement {
    name: string,
    alpha_2: string
    alpha_3: string
    isd_code: string
    numeric: string
}

export const getInitialStoreState = (): State => {
    return {
        loggedInUser: undefined,
        countries: undefined,
        notifications: [],
        activity_level: [],
        primary_goal: [],
        validation_errors: {}
    }
};
export type iStoreAction =
    | { type: 'ENQUEUE_SNACKBAR', notification: any, key: any }
    | { type: 'CLOSE_SNACKBAR', dismissAll: boolean, key: any }
    | { type: 'REMOVE_SNACKBAR', key: any }
    | { type: 'set_logged_in_user', loggedInUser: User | undefined }
    | { type: 'masters', masters: iMaster }
    | { type: 'logout', }


export function appReducer(state: State, action: iStoreAction): State {
    switch (action.type) {
        case 'set_logged_in_user':
            return {...state, loggedInUser: action.loggedInUser};
        case "masters":
            return {...state, ...action.masters};
        case 'logout':
            return {...state, loggedInUser: undefined};
        case 'ENQUEUE_SNACKBAR':
            return {
                ...state, notifications: [
                    ...state.notifications,
                    {
                        key: action.key,
                        ...action.notification,
                    },
                ],
            };
        case 'CLOSE_SNACKBAR':
            return {
                ...state, notifications: state.notifications.map(notification => (
                    (action.dismissAll || notification.key === action.key)
                        ? {...notification, dismissed: true}
                        : {...notification}
                )),
            };
        case "REMOVE_SNACKBAR":
            return {
                ...state,
                notifications: state.notifications.filter(
                    notification => notification.key !== action.key,
                ),
            };
        default:
            return {...state};
    }
}

export type iListResponseActions<T> =
    | { type: "loading" }
    | { type: "failed", error?: string }
    | { type: "failed_to_load", error?: string }
    | { type: "success", resource?: T }
    | { type: "query", query: iListingQuery }
    | { type: "go_to_page", page: number }
    | { type: "search", keyword: string }
    | { type: "per_page", per_page: number }


export interface iListResource<T> {
    loading: boolean,
    error?: string,
    resource?: T,
    error_block?: string,
    query: iListingQuery
}

export const listReducer: <TS extends iListResource<T>,
    T extends iListResponseActions<T>>(initialEntity: T) => Reducer<iListResource<T>, iListResponseActions<T>> = (initialEntity) => {
    return (state, action) => {
        if (!state) return {query: {}, loading: true};
        switch (action.type) {
            case 'loading':
                return {...state, loading: true};
            case 'failed':
                return {...state, loading: false, error: action.error};
            case 'failed_to_load':
                return {...state, loading: false, error_block: action.error};
            case 'success':
                return {...state, resource: action.resource, loading: false};
            case 'query':
                return {...state, query: {...action.query}};
            case 'go_to_page':
                return {...state, query: {...state.query, page: action.page}};
            case 'per_page':
                return {...state, query: {...state.query, per_page: action.per_page}};
            case 'search':
                return {...state, query: {...state.query, keyword: action.keyword}};
            default:
                return {...state, loading: true};
        }
    }
}

export const loading_action = (): iListResponseActions<any> => {
    return {type: "loading"}
};

export const per_page_row_change_action = (number: any): iListResponseActions<any> => {
    return {type: "per_page", per_page: number}
};
export const search_action = (keyword: string): iListResponseActions<any> => {
    return {type: "search", keyword: keyword}
};
export const current_page_change_action = (number: any, history?: any): iListResponseActions<any> => {
    history && history.push({
        search: getPageQuery(number)
    });
    return {type: "go_to_page", page: number}
};

export const failed_action = (error?: string): iListResponseActions<any> => {
    return {type: "failed", error: error}
};
export const failed_block_action = (error?: string): iListResponseActions<any> => {
    return {type: "failed_to_load", error: error}
};
export const success_action = (resource: any): iListResponseActions<any> => {
    return {type: "success", resource: resource}
};


export const loading_action_response = (): iResponseActions<any> => {
    return {type: "loading"}
};


export const failed_action_response = (error?: string): iResponseActions<any> => {
    return {type: "failed", error: error}
};
export const failed_block_action_response = (error?: string): iResponseActions<any> => {
    return {type: "failed_to_load", error: error}
};
export const success_action_response = (resource: any): iResponseActions<any> => {
    return {type: "success", response: resource}
};

export type iResponseActions<T> =
    | { type: "loading" }
    | { type: "failed_to_load", error?: string }
    | { type: "failed", error?: string }
    | { type: "success", response?: T }

export interface iResource<T> {
    loading?: boolean,
    error_block?: string,
    error?: string,
    response?: T,
}

export const responseReducer: <TS extends iResource<T>,
    T extends iResponseActions<T>>(initialEntity: T) => Reducer<iResource<T>, iResponseActions<T>> = (initialEntity) => {
    return (state, action) => {
        if (!state) return {loading: true};
        switch (action.type) {
            case 'loading':
                return {...state, loading: true};
            case 'failed_to_load':
                return {...state, loading: false, error_block: action.error};
            case 'failed':
                return {...state, loading: false, error: action.error};
            case 'success':
                return {...state, response: action.response, loading: false};
            default:
                return {...state, loading: false};
        }
    }
};

