import React, {Dispatch, useContext, useState} from "react";
import login_image from "../../assets/images/logo.png"
import {useHistory} from "react-router-dom";
import {Formik} from "formik";
import UserAPIs from "../../apis/user.apis";
import useIsMounted from "ismounted";
import * as yup from "yup";
import useStyles from "./style";
import {Button, CircularProgress, Fade, Grid, TextField, Typography} from "@material-ui/core";
import {iStoreAction} from "../../reducer";
import {AppDispatchContext} from "../../App";
import {HandleErrors} from "../../helper/form.helper";

export default function LoginPage() {
    const history = useHistory();

    const dispatch: Dispatch<iStoreAction> = useContext(AppDispatchContext);
    const [activeTabId, setActiveTabId] = useState(0);
    const classes = useStyles();
    const [error, setError] = useState<string>();
    const isMounted = useIsMounted()
    const loginSchema = yup.object({
        email: yup.string().email("Please enter valid email address").required("Please enter email ID"),
        password: yup.string().required("Please enter password"),
    });

    return <Grid container className={classes.container}>
        <div className={classes.logotypeContainer}>
            <img src={login_image} alt="" className={classes.logotypeImage}/>
        </div>
        <div className={classes.formContainer}>
            <div className={classes.form}>
                <React.Fragment>
                    <Typography variant="h1" className={classes.greeting}>
                        Good Morning, Admin
                    </Typography>

                    <Fade in={!!error}>
                        <Typography color="secondary" className={classes.errorMessage}>
                            Something is wrong with your login or password :(
                        </Typography>
                    </Fade>
                    <Formik
                        initialValues={{
                            email: "",
                            password: ""
                        }}
                        onSubmit={(values: any, helpers) => {
                            helpers.setSubmitting(true);
                            new UserAPIs().login(values).then((response) => {
                                if (isMounted.current) {
                                    if (UserAPIs.hasError(response)) {
                                        if(HandleErrors(response,helpers)){

                                        }
                                        helpers.setSubmitting(false);
                                    } else {
                                        return new UserAPIs().fetch_user().then((response) => {
                                            if (isMounted.current) {
                                                if (UserAPIs.hasError(response)) {
                                                    helpers.setSubmitting(false);
                                                } else {
                                                    dispatch({
                                                        type: "set_logged_in_user",
                                                        loggedInUser: response.user,
                                                    })
                                                    history.replace("/");
                                                }
                                            }

                                        });
                                    }
                                }
                            })
                        }}
                        validationSchema={loginSchema}
                    >
                        {({
                              handleSubmit,
                              handleChange,
                              values,
                              touched,
                              isSubmitting,
                              validateForm,
                              errors,
                          }: any) => {
                            return <form onSubmit={handleSubmit}>
                                <TextField
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField,
                                        },
                                    }}
                                    value={values.email}
                                    onChange={handleChange}
                                    margin="normal"
                                    placeholder="Email Address"
                                    type="text"
                                    name="email"
                                    fullWidth
                                    helperText={errors && errors.email}
                                    error={!!(errors && errors.email)}
                                />
                                <TextField
                                    id="password"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField,
                                        },
                                    }}
                                    onChange={handleChange}
                                    margin="normal"
                                    placeholder="Password"
                                    name="password"
                                    type="password"
                                    fullWidth
                                    helperText={errors && errors.password}
                                    error={!!(errors && errors.password)}
                                />
                                <div className={classes.formButtons}>
                                    {isSubmitting ? (
                                        <CircularProgress size={26} className={classes.loginLoader}/>
                                    ) : (
                                        <Button
                                            disabled={
                                                values.email.length === 0 || values.password.length === 0
                                            }
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                        >
                                            Login
                                        </Button>
                                    )}
                                    <Button
                                        color="primary"
                                        size="large"
                                        className={classes.forgetButton}
                                    >
                                        Forget Password
                                    </Button>
                                </div>
                            </form>

                        }}
                    </Formik>


                </React.Fragment>
            </div>
            <Typography color="primary" className={classes.copyright}>
                © 2020-{new Date().getFullYear()} <a style={{textDecoration: 'none', color: 'inherit'}}
                                                     href="https://sprintwheel.com" rel="noopener noreferrer"
                                                     target="_blank">Fitapp</a> All rights reserved.
            </Typography>
        </div>
    </Grid>
}
