import React, {useEffect, useState} from "react";
import {Drawer, IconButton, List} from "@material-ui/core";
import {ArrowBack as ArrowBackIcon, Home as HomeIcon, Person,} from "@material-ui/icons";
import {useTheme} from "@material-ui/styles";
import {withRouter} from "react-router-dom";
import classNames from "classnames";
import StorageIcon from '@material-ui/icons/Storage';
import SettingsIcon from '@material-ui/icons/Settings';
import FitnessCenter from '@material-ui/icons/FitnessCenter';
import VideoLibrary from '@material-ui/icons/VideoLibrary';
// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {toggleSidebar, useLayoutDispatch, useLayoutState,} from "../../context/LayoutContext";

const structure = [
    {id: 0, label: "Dashboard", link: "/dashboard", icon: <HomeIcon/>},
    {
        id: 1,
        label: "Customer",
        link: "/customer",
        icon: <Person/>,
    },
    {
        id: 2,
        label: "Training Sessions",
        link: "/training-sessions",
        icon: <FitnessCenter/>,
    },
    {
        id: 3,
        label: "Training Videos",
        link: "/training-videos",
        icon: <VideoLibrary/>,
    },
    {
        id: 4,
        label: "Movements",
        link: "/movements",
        icon: <VideoLibrary/>,
    },
    {
        id: 5,
        label: "Settings",
        link: "/settings",
        icon: <SettingsIcon/>,
    },

];

function Sidebar({location}: any) {
    const classes: any = useStyles();
    const theme = useTheme<any>();

    // global
    const {isSidebarOpened} = useLayoutState();
    const layoutDispatch = useLayoutDispatch();

    // local
    const [isPermanent, setPermanent] = useState(true);

    useEffect(function () {
        window.addEventListener("resize", handleWindowWidthChange);
        handleWindowWidthChange();
        return function cleanup() {
            window.removeEventListener("resize", handleWindowWidthChange);
        };
    });

    return (
        <Drawer
            variant={isPermanent ? "permanent" : "temporary"}
            className={classNames(classes.drawer, {
                [classes.drawerOpen]: isSidebarOpened,
                [classes.drawerClose]: !isSidebarOpened,
            })}
            classes={{
                paper: classNames({
                    [classes.drawerOpen]: isSidebarOpened,
                    [classes.drawerClose]: !isSidebarOpened,
                }),
            }}
            open={isSidebarOpened}
        >
            <div className={classes.toolbar}/>
            <div className={classes.mobileBackButton}>
                <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
                    <ArrowBackIcon
                        classes={{
                            root: classNames(classes.headerIcon, classes.headerIconCollapse),
                        }}
                    />
                </IconButton>
            </div>
            <List className={classes.sidebarList}>
                {structure.map(link => (
                    <SidebarLink
                        key={link.id}
                        location={location}
                        isSidebarOpened={isSidebarOpened}
                        {...link}
                    />
                ))}
            </List>
        </Drawer>
    );

    // ##################################################################
    function handleWindowWidthChange() {
        const windowWidth = window.innerWidth;
        const breakpointWidth = theme.breakpoints.values.md;
        const isSmallScreen = windowWidth < breakpointWidth;

        if (isSmallScreen && isPermanent) {
            setPermanent(false);
        } else if (!isSmallScreen && !isPermanent) {
            setPermanent(true);
        }
    }
}

export default withRouter(Sidebar);
