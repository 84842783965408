import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";


export default makeStyles((theme: Theme) => ({
    input:{
        borderLeft:"none",
        borderRight:"none",
        borderTop:"none",
        backgroundColor:"#F6F7FF",
        width:"80%",
        outline:"none"
    },
    heading:{
        textAlign:"center",
        fontWeight:"bold",
    },
    addCustomerButton:{
        backgroundColor:"#03506f",
        color:"white",
        padding:"6px",
        borderRadius: "4px",
        fontWeight:"bold",
        cursor:"pointer",
        '&:hover': {
            backgroundColor: "black",
        },
    },
    centered:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
}));
