import React, {Reducer, useCallback, useContext, useEffect, useReducer, useState} from "react";
import {Button, CircularProgress, Grid, IconButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle";
import {
    current_page_change_action,
    failed_action,
    iListResource,
    iListResponseActions,
    listReducer,
    loading_action,
    per_page_row_change_action,
    search_action,
    success_action
} from "../../reducer";
import useIsMounted from "ismounted";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat"
import UTC from "dayjs/plugin/utc"
import NewTrainingVideo from "./NewTrainingVideo";
import {iBasicListingResponse} from "../../types/all";
import {Create, Delete} from "@material-ui/icons";
import {NotificationContext} from "../../App";
import QueryParser from "../../components/QueryParser";
import {useHistory, useLocation} from "react-router-dom";
import TrainingVideosAPIs, {iTrainingVideos} from "../../apis/trainingVideos.apis";
import AppLoader from "../../components/Loader/AppLoader";

dayjs.extend(LocalizedFormat)
dayjs.extend(UTC)

const useStyles = makeStyles(theme => ({
    tableOverflow: {
        overflow: 'auto'
    }
}))

export default function TrainingVideos() {
    const location = useLocation();
    const history = useHistory();
    const [{
        error, error_block, resource, loading, query
    }, dispatchList] = useReducer<Reducer<iListResource<iBasicListingResponse<iTrainingVideos>>, iListResponseActions<iBasicListingResponse<iTrainingVideos>>>>
    (listReducer<iListResource<iBasicListingResponse<iTrainingVideos>>, any>({}), {
        query: QueryParser(location, {filter_is_movement: "1"}),
        loading: false,
        error_block: ""
    });
    const [trainingVideosToBeEdited, setTrainingVideosToBeEdited] = useState<iTrainingVideos>();

    const notificationContext = useContext(NotificationContext);

    const classes = useStyles();

    const isMounted = useIsMounted();

    const loadTrainingVideos = useCallback(() => {
        dispatchList(loading_action());
        new TrainingVideosAPIs().list(query).then((res) => {
            if (isMounted.current) {
                if (TrainingVideosAPIs.hasError(res)) {
                    dispatchList(failed_action(res.message));
                } else {
                    dispatchList(success_action(res.training_videos));
                }
            }
        })
    }, [query, isMounted]);

    useEffect(() => {
        loadTrainingVideos();
    }, [query])
    const [addTrainingVideo, setAddNewTrainingVideo] = useState(false);

    return (
        <>
            <PageTitle title="Training Videos" button={<Button
                variant="contained"
                size="medium"
                color="secondary"
                onClick={() => {
                    setTrainingVideosToBeEdited(undefined)
                    setAddNewTrainingVideo(true)
                }}
            >
                Add Training Videos
            </Button>}/>
            <Grid container spacing={4}>
                {
                    loading && <CircularProgress/>
                }
                <Grid item xs={12}>
                    <MUIDataTable

                        title="Training Videos"
                        data={
                            (
                                resource &&
                                resource.data &&
                                resource.data.map((res) => {
                                    return [
                                        <div>
                                            <p>
                                                en: {res.title}
                                            </p>
                                            <p>
                                                ko: {res.title_ko}
                                            </p>
                                            <p>
                                                es: {res.title_es}
                                            </p>
                                            <p>
                                                ja: {res.title_ja}
                                            </p>
                                            <p>
                                                zh: {res.title_zh}
                                            </p>

                                        </div>
                                        , <div>
                                            <p>
                                                en: {res.description}
                                            </p>
                                            <p>
                                                ko: {res.description_ko}
                                            </p>
                                            <p>
                                                es: {res.description_es}
                                            </p>
                                            <p>
                                                ja: {res.description_ja}
                                            </p>
                                            <p>
                                                zh: {res.description_zh}
                                            </p>

                                        </div>,
                                        <video width="150" height="60">
                                            <source src={res.video_path_full_url} type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>,
                                        <div>
                                            <IconButton onClick={() => {
                                                setTrainingVideosToBeEdited(res);
                                                setAddNewTrainingVideo(true);
                                            }}>
                                                <Create/>
                                            </IconButton>
                                            <IconButton onClick={() => {
                                                const confirm = window.confirm("Do you really want to delete this training session?");
                                                if (confirm) {
                                                    new TrainingVideosAPIs().delete(res.uid).then((del_response => {
                                                        if (TrainingVideosAPIs.hasError(del_response, notificationContext)) {

                                                        } else {
                                                            loadTrainingVideos();
                                                        }
                                                    }))
                                                }

                                            }}>
                                                <Delete color={"error"}/>
                                            </IconButton>

                                        </div>
                                    ]
                                })
                            ) || []
                        }
                        columns={["Title", "Description", "Video", "Actions"]}

                        options={{
                            serverSide: true,
                            sort: true,

                            print: false,
                            viewColumns: false,
                            onSearchChange: (keyword) => {
                                dispatchList(search_action(keyword || ""));
                            },
                            onChangePage: (currentPage => {
                                dispatchList(current_page_change_action(currentPage, history));
                            }),
                            onChangeRowsPerPage: (numberOfRows => {
                                dispatchList(per_page_row_change_action(numberOfRows));
                            }),
                            filter: false,
                            download: false,
                            count: resource && resource.total,
                            rowsPerPage: query.per_page,
                            onDownload: (buildHead) => {
                                // new StoryAPIs().download_logs(query).then()
                                return false
                            },
                            textLabels: {
                                body: {
                                    noMatch: loading ?
                                        <AppLoader/> :
                                        'Sorry, there is no matching data to display',
                                },
                            }
                        }}
                    />

                </Grid>
            </Grid>
            <NewTrainingVideo
                default_values={trainingVideosToBeEdited}
                isModalVisible={addTrainingVideo} setModalVisible={setAddNewTrainingVideo}
                trainingVideoCreated={() => {
                    loadTrainingVideos()
                    setAddNewTrainingVideo(false)
                }}/>
        </>
    );
}
