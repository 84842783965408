import BaseAPIs from "./base.apis";
import axios from 'axios';
import {
    iApiBasicResponse,
    iBasicListingResponse,
    iCollectionElement,
    iFetchUserResponse,
    iUserLoginResponse
} from "../types/all";
import {ObjectFlatter} from "./resource.apis";


export interface iTrainingVideos extends iCollectionElement {
    title: string,
    title_ko:string,
    title_es:string,
    title_ja:string,
    title_zh:string,
    description:string,
    description_ko:string,
    description_es:string,
    description_ja:string,
    description_zh:string,
    training_video_file:any,
    training_video_thumbnail_file:any,
    is_enabled: boolean,
    is_movement: boolean,
    video_path_full_url:string,
    level:string,
}

export interface iTrainingVideosListResponse extends iApiBasicResponse {
    training_videos?: iBasicListingResponse<iTrainingVideos>
}

export default class TrainingVideosAPIs extends BaseAPIs {

    create = async (values: any,onUploadProgress?: (progressEvent: ProgressEvent) => void): Promise<iTrainingVideosListResponse> => {
        let csrfToken: string = await new BaseAPIs().getCsrfToken();
        if (!csrfToken) return {statusCode: 999, message: "Network error!"};
        let fd=new FormData();
        // fd.set("title",values.title);
        // fd.set("description",values.description);
        // fd.set("training_video_file",values.training_video_file);
        let flattenedParams = new ObjectFlatter(values);
        flattenedParams.get_form_data().forEach(entry => {
            fd.set(entry.key, entry.value);
        });
        fd.set("_token",  csrfToken);
        fd.set("training_video_file",  values.training_video_file);
        fd.set("training_video_thumbnail_file",  values.training_video_thumbnail_file);

        fd.set("level","elite");
        return axios
            .post(this.getApiBaseURL() + "/_api/v1/admin/training-videos", fd, {
                headers: {"Content-Type": "multipart/form-data"},
                withCredentials: true,
                onUploadProgress:onUploadProgress,
            })
            .then((res): iTrainingVideosListResponse => ({statusCode: res.status, ...res.data}))
            .catch((error): any => {
                if (error && error.response && error.response.status && error.response.data) {
                    return {
                        message: "Network Error", ...error.response.data, statusCode: error.response.status
                    }
                } else {
                    return {statusCode: 999, message: "Network Error"};
                }
            })
    };
    delete = async (uid: any): Promise<iApiBasicResponse> => {
        let csrfToken: string = await new BaseAPIs().getCsrfToken();
        if (!csrfToken) return {statusCode: 999, message: "Network error!"};
        let values: any = {};
        values["_token"] = csrfToken
        values["_method"] = "DELETE"
        return axios
            .post(this.getApiBaseURL() + "/_api/v1/admin/training-videos/" + uid, values, {
                headers: {"Content-Type": "application/json"},
                withCredentials: true,
            })
            .then((res): iApiBasicResponse => ({statusCode: res.status, ...res.data}))
            .catch((error): any => {
                if (error && error.response && error.response.status && error.response.data) {
                    return {
                        message: "Network Error", ...error.response.data, statusCode: error.response.status
                    }
                } else {
                    return {statusCode: 999, message: "Network Error"};
                }
            })
    };
    edit = async (uid: any, values: any,onUploadProgress?: (progressEvent: ProgressEvent) => void): Promise<iTrainingVideosListResponse> => {
        let csrfToken: string = await new BaseAPIs().getCsrfToken();
        if (!csrfToken) return {statusCode: 999, message: "Network error!"};
        let fd=new FormData();
        let flattenedParams = new ObjectFlatter(values);
        flattenedParams.get_form_data().forEach(entry => {
            fd.set(entry.key, entry.value);
        });
        fd.set("_token",  csrfToken);
        fd.set("training_video_file",  values.training_video_file);
        fd.set("training_video_thumbnail_file",  values.training_video_thumbnail_file);
        fd.set("level","elite");
        fd.set("_method","PATCH");
        return axios
            .post(this.getApiBaseURL() + "/_api/v1/admin/training-videos/" + uid, fd, {
                headers: {"Content-Type": "multipart/form-data"},
                withCredentials: true,
                onUploadProgress:onUploadProgress,
            })
            .then((res): iTrainingVideosListResponse => ({statusCode: res.status, ...res.data}))
            .catch((error): any => {
                if (error && error.response && error.response.status && error.response.data) {
                    return {
                        message: "Network Error", ...error.response.data, statusCode: error.response.status
                    }
                } else {
                    return {statusCode: 999, message: "Network Error"};
                }
            })
    };
    list = async (query: any): Promise<iTrainingVideosListResponse> => {
        return axios
            .get(this.getApiBaseURL() + "/_api/v1/admin/training-videos", {
                withCredentials: true,
                params: query
            })
            .then((res): iTrainingVideosListResponse => ({statusCode: res.status, ...res.data}))
            .catch((error): any => {
                if (error && error.response && error.response.status && error.response.data) {
                    return {
                        message: "Network Error", ...error.response.data, statusCode: error.response.status
                    }
                } else {
                    return {statusCode: 999, message: "Network Error"};
                }
            })
    };
}
