import React from "react";
import {CircularProgress} from "@mui/material";


export default function AppLoader() {
    return <div >
        <CircularProgress />
    </div>

}
