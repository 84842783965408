import BaseAPIs from "./base.apis";
import axios, {CancelTokenSource} from 'axios';
import {
    iApiBasicResponse,
    iBasicListingResponse,
    iCollectionElement,
    iFetchUserResponse,
    iUserLoginResponse
} from "../types/all";
import {ObjectFlatter} from "./resource.apis";

export interface iMovement extends iCollectionElement{
    title: string,
    title_ko: string,
    title_es: string,
    title_ja: string,
    title_zh: string,
    code: string,
    description: string,
    description_ko: string,
    description_es: string,
    description_ja: string,
    description_zh: string,
    is_enabled: boolean,
    level: string,
    accessories: string,
    accessories_ko: string,
    accessories_es: string,
    accessories_zh: string,
    accessories_ja: string,
    sets:iSet[]
    thumbnail_path_full_url:string
    video_path_full_url:string
}
export interface iSet{
    reps:string,
    weight:string,
    time_minutes:string
}
export interface iMovementListResponse extends iApiBasicResponse{
    movements?:iBasicListingResponse<iMovement>
}
export interface iMovementViewResponse extends iApiBasicResponse{
    movement?:iMovement
}

export default class MovementAPIs extends BaseAPIs {

    create = async (values: any): Promise<iMovementListResponse> => {
        let csrfToken: string = await new BaseAPIs().getCsrfToken();
        if (!csrfToken) return {statusCode: 999, message: "Network error!"};

        values["_token"] = csrfToken
        let fd = new FormData();
        let flattenedParams = new ObjectFlatter(values);
        flattenedParams.get_form_data().forEach(entry => {
            fd.set(entry.key, entry.value);
        });
        fd.set("movement_video_file", values.movement_video_file);
        fd.set("movement_video_thumbnail_file", values.movement_video_thumbnail_file);
        return axios
            .post(this.getApiBaseURL() + "/_api/v1/admin/movements", fd, {
                headers: {"Content-Type": "multipart/form-data"},
                withCredentials: true,
            })
            .then((res): iMovementListResponse => ({statusCode: res.status, ...res.data}))
            .catch((error): any => {
                if (error && error.response && error.response.status && error.response.data) {
                    return {
                        message: "Network Error", ...error.response.data, statusCode: error.response.status
                    }
                } else {
                    return {statusCode: 999, message: "Network Error"};
                }
            })
    };
    delete = async (uid: any): Promise<iApiBasicResponse> => {
        let csrfToken: string = await new BaseAPIs().getCsrfToken();
        if (!csrfToken) return {statusCode: 999, message: "Network error!"};
        let values: any = {};
        values["_token"] = csrfToken
        values["_method"] = "DELETE"
        return axios
            .post(this.getApiBaseURL() + "/_api/v1/admin/movements/" + uid, values, {
                headers: {"Content-Type": "application/json"},
                withCredentials: true,
            })
            .then((res): iApiBasicResponse => ({statusCode: res.status, ...res.data}))
            .catch((error): any => {
                if (error && error.response && error.response.status && error.response.data) {
                    return {
                        message: "Network Error", ...error.response.data, statusCode: error.response.status
                    }
                } else {
                    return {statusCode: 999, message: "Network Error"};
                }
            })
    };
    edit = async (uid: any, values: any): Promise<iMovementListResponse> => {
        let csrfToken: string = await new BaseAPIs().getCsrfToken();
        if (!csrfToken) return {statusCode: 999, message: "Network error!"};
        values["_token"] = csrfToken
        values["_method"] = "PATCH"
        let fd = new FormData();
        let flattenedParams = new ObjectFlatter(values);
        flattenedParams.get_form_data().forEach(entry => {
            fd.set(entry.key, entry.value);
        });
        fd.set("movement_video_file", values.movement_video_file);
        fd.set("movement_video_thumbnail_file", values.movement_video_thumbnail_file);
        return axios
            .post(this.getApiBaseURL() + "/_api/v1/admin/movements/" + uid, fd, {
                headers: {"Content-Type": "multipart/form-data"},
                withCredentials: true,
            })
            .then((res): iMovementListResponse => ({statusCode: res.status, ...res.data}))
            .catch((error): any => {
                if (error && error.response && error.response.status && error.response.data) {
                    return {
                        message: "Network Error", ...error.response.data, statusCode: error.response.status
                    }
                } else {
                    return {statusCode: 999, message: "Network Error"};
                }
            })
    };
    list = async (query: any): Promise<iMovementListResponse> => {
        return axios
            .get(this.getApiBaseURL() + "/_api/v1/admin/movements", {
                withCredentials: true,
                params: query
            })
            .then((res): iMovementListResponse => ({statusCode: res.status, ...res.data}))
            .catch((error): any => {
                if (error && error.response && error.response.status && error.response.data) {
                    return {
                        message: "Network Error", ...error.response.data, statusCode: error.response.status
                    }
                } else {
                    return {statusCode: 999, message: "Network Error"};
                }
            })
    };
    view = async (uid: string): Promise<iMovementViewResponse> => {
        return axios
            .get(this.getApiBaseURL() + "/_api/v1/admin/movements/" + uid, {
                withCredentials: true,
            })
            .then((res): iMovementViewResponse => ({statusCode: res.status, ...res.data}))
            .catch((error): any => {
                if (error && error.response && error.response.status && error.response.data) {
                    return {
                        message: "Network Error", ...error.response.data, statusCode: error.response.status
                    }
                } else {
                    return {statusCode: 999, message: "Network Error"};
                }
            })
    };
}
