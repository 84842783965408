import React from 'react'

export const useIsMounted = () => {
    const ref = React.useRef(false)
    const [, setIsMounted] = React.useState(false)
    React.useEffect((): any => {
        ref.current = true
        setIsMounted(true)
        return () => (ref.current = false)
    }, [])
    return () => ref.current
}

export function JSONTryParse(date: string) {
    try {
        return JSON.parse(date);
    } catch (e) {
        return null;
    }
}

export function validateWords(value: string, max_length: number) {
    if (value.split(' ').length <= max_length) {
        return null;
    }
    return "You have reached the maximum word limit"
}

export const convertToCamelCase=(word:string)=>{
    word=word[0].toUpperCase() +word.slice(1);
    word = word.replace(/_/g, " ");
    return word;
}
export const getFlatKeys = (o: any, prefix = ''): string[] => {
    return Object.keys(o).flatMap(k => {
        const dot = prefix ? '.' : '';
        const new_k = isNaN(+k) ? `${dot}${k}` : `[${k}]`;
        const new_prefix = `${prefix}${new_k}`;
        return Object(o[k]) === o[k] ? getFlatKeys(o[k], new_prefix) : [new_prefix]
    });
}

export function getNestedValue(o: any, s: string) {
    try {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        let a = s.split('.');
        for (let i = 0, n = a.length; i < n; ++i) {
            let k = a[i];
            if (k !== undefined && o !== undefined) {
                if (k in o) {
                    o = o[k];
                } else {
                    return "";
                }
            } else {
                return "";
            }

        }
        return o;
    } catch (e) {
        return ""
    }

}
