import BaseAPIs from "./base.apis";
import axios from 'axios';
import {iApiBasicResponse, iFetchUserResponse, iUserLoginResponse} from "../types/all";


export default class UserAPIs extends BaseAPIs {

    logout = async (): Promise<iApiBasicResponse> => {
        let csrfToken: string = await new BaseAPIs().getCsrfToken();
        if (!csrfToken) return {statusCode: 999, message: "Network error!"};
        let values:any={}
        values["_token"] = csrfToken
        return axios
            .post(this.getApiBaseURL() + "/_api/v1/logout",  values,{
                withCredentials: true,
            })
            .then((res): iApiBasicResponse => ({statusCode: res.status, ...res.data}))
            .catch((error): any => {
                return this.handleCatch(error);
            })
    };
    login = async (values: any): Promise<iUserLoginResponse> => {

        let csrfToken: string = await new BaseAPIs().getCsrfToken();
        if (!csrfToken) return {statusCode: 999, message: "Network error!"};

        values["_token"] = csrfToken
        return axios
            .post(this.getApiBaseURL() + "/_api/v1/login", values, {
                headers: {"Content-Type": "application/json"},
                withCredentials: true,
            })
            .then((res): iUserLoginResponse => ({statusCode: res.status, ...res.data}))
            .catch((error): any => {
                if (error && error.response && error.response.status && error.response.data) {
                    return {
                        message: "Network Error", ...error.response.data, statusCode: error.response.status
                    }
                } else {
                    return {statusCode: 999, message: "Network Error"};
                }
            })
    };

    fetch_user = async (): Promise<iFetchUserResponse> => {
        return axios
            .get(this.getApiBaseURL() + "/_api/v1/user", {
                withCredentials: true,
            })
            .then((res): iFetchUserResponse => ({statusCode: res.status, ...res.data}))
            .catch((error): any => {
                if (error && error.response && error.response.status && error.response.data) {
                    return {
                        message: "Network Error", ...error.response.data, statusCode: error.response.status
                    }
                } else {
                    return {statusCode: 999, message: "Network Error"};
                }
            })
    };
}
