import React, {createContext, useReducer} from 'react';
import {BrowserRouter} from "react-router-dom";
import Router from "./pages/Router";
import {CssBaseline, ThemeProvider} from '@material-ui/core';
import theme from "./themes";
import {LayoutProvider} from './context/LayoutContext';
import {iApiBasicResponse} from "./types/all";
import {appReducer, getInitialStoreState, State} from "./reducer";
import BaseAPIs from "./apis/base.apis";
import {closeSnackbar as closeSnackbarAction, enqueueSnackbar as enqueueSnackbarAction} from "./components/Notifier";

export function Loading() {
    return <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
}

export interface NotificationDispatch {
    pushSuccessNotification: (message?: string) => void,
    pushErrorNotification: (message?: string) => void,
    pushNotification: (response: iApiBasicResponse) => void,
}

export const NotificationContext = createContext<NotificationDispatch>({
    pushSuccessNotification: message => {
    },
    pushErrorNotification: message => {
    },
    pushNotification: response => {
    },
});
export const AppStateContext = createContext<State>(getInitialStoreState());
export const AppDispatchContext = createContext<any>({});

export interface NotificationDispatch {
    pushSuccessNotification: (message?: string) => void,
    pushErrorNotification: (message?: string) => void,
    pushNotification: (response: iApiBasicResponse) => void,
}

export default function App() {
    const [state, dispatch] = useReducer(appReducer, getInitialStoreState());

    // @ts-ignore
    const enqueueSnackbar = (...args: any[]) => dispatch(enqueueSnackbarAction(...args));
    // @ts-ignore
    const closeSnackbar = (...args: any[]) => dispatch(closeSnackbarAction(...args));

    const pushSuccessNotification = (message?: string) => {
        if (message) {

            // NOTE:
            // if you want to be able to dispatch a `closeSnackbar` action later on,
            // you SHOULD pass your own `key` in the options. `key` can be any sequence
            // of number or characters, but it has to be unique for a given snackbar.
            enqueueSnackbar({
                message: message,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                    autoHideDuration: 3000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                },

            });
        }

    };
    const pushErrorNotification = (message?: string) => {
        if (message) {
            // NOTE:
            // if you want to be able to dispatch a `closeSnackbar` action later on,
            // you SHOULD pass your own `key` in the options. `key` can be any sequence
            // of number or characters, but it has to be unique for a given snackbar.
            enqueueSnackbar({
                message: message,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'danger',

                    autoHideDuration: 3000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                },

            });
        }

    };
    const pushNotification = (response: iApiBasicResponse) => {
        if (BaseAPIs.hasErrorNotification(response)) {
            pushErrorNotification(response.message);
        } else {
            pushSuccessNotification(response.message)
        }
    };
    return <AppDispatchContext.Provider value={dispatch}>
        <AppStateContext.Provider value={state}>

            <NotificationContext.Provider value={{
                pushErrorNotification: pushErrorNotification,
                pushSuccessNotification: pushSuccessNotification,
                pushNotification: pushNotification,
            }}> <LayoutProvider>
                <ThemeProvider theme={theme.default}>
                    <CssBaseline/>
                    <BrowserRouter>
                        <Router/>
                    </BrowserRouter>
                </ThemeProvider>

            </LayoutProvider>
            </NotificationContext.Provider>
        </AppStateContext.Provider>
    </AppDispatchContext.Provider>
}

