import React, {Dispatch, useContext, useEffect, useState} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {AppDispatchContext, AppStateContext, Loading} from "../App";
import UserAPIs from "../apis/user.apis";
import {iStoreAction} from "../reducer";
import useIsMounted from "ismounted";
import Layout from "../components/Layout/Layout";
import LoginPage from "./Login/LoginPage";
import CommonApis from "../apis/common.apis";

export default function Router() {

    const dispatch: Dispatch<iStoreAction> = useContext(AppDispatchContext);
    const [loading, setLoading] = useState(true);
    const isMounted = useIsMounted();
    useEffect(() => {
        setLoading(true);
        new UserAPIs().fetch_user().then((response) => {
            if (isMounted.current) {
                if (UserAPIs.hasError(response)) {
                } else {
                    dispatch({
                        type: "set_logged_in_user",
                        loggedInUser: response.user,
                    })
                }
                setLoading(false)

            }

        });
    }, [isMounted, dispatch])

    useEffect(() => {
        new CommonApis().getAllMasters().then((res) => {
            if (isMounted.current) {
                if (!res || CommonApis.hasError(res)) {

                } else {
                    dispatch({type: "masters", masters: res});
                }
            }
        });
    }, [isMounted]);

    if (loading) {
        return <Loading/>;
    }
    return <Switch>
        <WithNoAuthRoute exact path="/login"><LoginPage/></WithNoAuthRoute>
        <WithAuthRoute path="/"><Layout/></WithAuthRoute>
        <Redirect to="/login"/>
    </Switch>;
}

function WithAuthRoute({children, ...rest}: any) {
    const {loggedInUser} = useContext(AppStateContext);
    return (
        <Route
            {...rest}
            render={({location}) =>
                (loggedInUser && loggedInUser.email) ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    );
}

function WithNoAuthRoute({children, ...rest}: any) {
    const {loggedInUser} = useContext(AppStateContext);
    return (
        <Route
            {...rest}
            render={({location}) =>
                (!loggedInUser || !loggedInUser.email) ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    );
}
