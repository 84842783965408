import React, {Reducer, useCallback, useContext, useEffect, useReducer, useState} from "react";
import {Button, CircularProgress, Grid, IconButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle";
import {
    current_page_change_action,
    failed_action,
    iListResource,
    iListResponseActions,
    listReducer,
    loading_action,
    per_page_row_change_action,
    search_action,
    success_action
} from "../../reducer";
import useIsMounted from "ismounted";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat"
import UTC from "dayjs/plugin/utc"
import NewMovement from "./NewMovement";
import {iBasicListingResponse} from "../../types/all";
import {Create, Delete} from "@material-ui/icons";
import {NotificationContext} from "../../App";
import QueryParser from "../../components/QueryParser";
import {useHistory, useLocation} from "react-router-dom";
import {CancelTokenSource} from "axios";
import AppLoader from "../../components/Loader/AppLoader";
import MovementAPIs, {iMovement} from "../../apis/movements.apis";

dayjs.extend(LocalizedFormat)
dayjs.extend(UTC)

const useStyles = makeStyles(theme => ({
    tableOverflow: {
        overflow: 'auto'
    }
}))

export default function Movements() {
    const location = useLocation();
    const history = useHistory();
    const [{
        error, error_block, resource, loading, query
    }, dispatchList] = useReducer<Reducer<iListResource<iBasicListingResponse<iMovement>>,
        iListResponseActions<iBasicListingResponse<iMovement>>>>
    (listReducer<iListResource<iBasicListingResponse<iMovement>>, any>({}), {
        query: QueryParser(location),
        loading: false,
        error_block: ""
    });
    const [movementToBeEdited, setMovementToBeEdited] = useState<iMovement>();

    const notificationContext = useContext(NotificationContext);

    const classes = useStyles();

    const isMounted = useIsMounted();

    const loadMovements = useCallback((source?: CancelTokenSource) => {
        dispatchList(loading_action());
        new MovementAPIs().list(query).then((res) => {
            if (isMounted.current) {
                if (MovementAPIs.hasError(res)) {
                    dispatchList(failed_action(res.message));
                } else {
                    dispatchList(success_action(res.movements));
                }
            }
        })
    }, [query, isMounted]);


    useEffect(loadMovements, [query])

    const [addMovement, setAddMovement] = useState(false);
    return (
        <>
            <PageTitle title="Movements" button={<Button
                variant="contained"
                size="medium"
                color="secondary"
                onClick={() => {
                    setMovementToBeEdited(undefined)
                    setAddMovement(true)
                }}
            >
                Add Movement
            </Button>}/>
            <Grid container spacing={4}>
                {
                    loading && <CircularProgress/>
                }
                <Grid item xs={12}>
                    <MUIDataTable

                        title="Movements"
                        data={
                            (
                                resource &&
                                resource.data &&
                                resource.data.map((res) => {
                                    return [
                                        <div>
                                            <p>
                                                en: {res.title}
                                            </p>
                                            <p>
                                                ko: {res.title_ko}
                                            </p>
                                            <p>
                                                es: {res.title_es}
                                            </p>
                                            <p>
                                                ja: {res.title_ja}
                                            </p>
                                            <p>
                                                zh: {res.title_zh}
                                            </p>

                                        </div>,<div>
                                            {
                                                res.code && <p>
                                                    {"{vo"}{res.code.toUpperCase()}{"}"}
                                                </p>
                                            }
                                        </div>,<div>
                                            <p>
                                                en: {res.description}
                                            </p>
                                            <p>
                                                ko: {res.description_ko}
                                            </p>
                                            <p>
                                                es: {res.description_es}
                                            </p>
                                            <p>
                                                ja: {res.description_ja}
                                            </p>
                                            <p>
                                                zh: {res.description_zh}
                                            </p>

                                        </div>, res.level,
                                        <video width="150" height="60">
                                            <source src={res.video_path_full_url} type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>,
                                        <div>
                                            <IconButton onClick={() => {
                                                setMovementToBeEdited(res);
                                                setAddMovement(true);
                                            }}>
                                                <Create/>
                                            </IconButton>
                                            <IconButton onClick={() => {
                                                const confirm = window.confirm("Do you really want to delete this movement?");
                                                if (confirm) {
                                                    new MovementAPIs().delete(res.uid).then((del_response => {
                                                        if (MovementAPIs.hasError(del_response, notificationContext)) {

                                                        } else {
                                                            loadMovements();
                                                        }
                                                    }))
                                                }

                                            }}>
                                                <Delete color={"error"}/>
                                            </IconButton>

                                        </div>
                                    ]
                                })
                            ) || []
                        }
                        columns={["Title","Code", "Description", "Level", "Video", "Actions"]}

                        options={{
                            serverSide: true,
                            sort: true,

                            print: false,
                            viewColumns: false,
                            onSearchChange: (keyword) => {
                                dispatchList(search_action(keyword || ""));
                            },
                            onChangePage: (currentPage => {
                                dispatchList(current_page_change_action(currentPage, history));
                            }),
                            onChangeRowsPerPage: (numberOfRows => {
                                dispatchList(per_page_row_change_action(numberOfRows));
                            }),
                            filter: false,
                            download: false,
                            count: resource && resource.total,
                            rowsPerPage: query.per_page,
                            onDownload: (buildHead) => {
                                // new StoryAPIs().download_logs(query).then()
                                return false
                            },
                            textLabels: {
                                body: {
                                    noMatch: loading ?
                                        <AppLoader/> :
                                        'Sorry, there is no matching data to display',
                                },
                            }
                        }}
                    />

                </Grid>
            </Grid>
            <NewMovement
                default_values={movementToBeEdited}
                isModalVisible={addMovement} setModalVisible={setAddMovement}
                movementCreated={() => {
                    loadMovements()
                    setAddMovement(false)
                }}/>
        </>
    );
}
