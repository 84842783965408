import defaultTheme from "./default";

import {createMuiTheme} from "@material-ui/core";

const overrides = {
    typography: {
        h1: {
            fontSize: "2rem",
        },
        h2: {
            fontSize: "1.5rem",
        },
        h3: {
            fontSize: "1.25rem",
        },
        h4: {
            fontSize: "1.2rem",
        },
        h5: {
            fontSize: "1.12rem",
        },
        h6: {
            fontSize: "1rem",
        },
    },
    body: {
        width: "100vw"
    }
};

const themes = {
    default: createMuiTheme({...defaultTheme, ...overrides}),
};

export default themes;
